@font-face {
    font-family: 'Ubuntu Regular';
    font-weight: normal;
    src: local('Ubuntu Regular'), url(./fonts/Ubuntu-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Ubuntu Bold';
    font-weight: bold;
    src: local('Ubuntu Bold'), url(./fonts/Ubuntu-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Ubuntu Light';
    src: local('Ubuntu Light'), url(./fonts/Ubuntu-Light.ttf) format('truetype');
}

*{
    margin: 0;
}

body {
    font-family: 'Ubuntu Regular', sans-serif;
}

.iq__index{
    padding: 0 16px;
}

@media (min-width: 960px) {
    .iq__index{
        padding: 0 40px;
    }
}